export var CommunityRole;
(function (CommunityRole) {
    CommunityRole["Owner"] = "owner";
    CommunityRole["Admin"] = "admin";
    CommunityRole["Moderator"] = "moderator";
    CommunityRole["Editor"] = "editor";
    CommunityRole["Viewer"] = "viewer";
    CommunityRole["Commenter"] = "commenter";
    CommunityRole["Banned"] = "banned";
})(CommunityRole || (CommunityRole = {}));
export var ChannelType;
(function (ChannelType) {
    ChannelType["Feed"] = "feed";
    ChannelType["Chat"] = "chat";
    ChannelType["Events"] = "events";
})(ChannelType || (ChannelType = {}));
export var PermissionLevel;
(function (PermissionLevel) {
    PermissionLevel["Unavailable"] = "unavailable";
    PermissionLevel["Read"] = "read";
    PermissionLevel["Comment"] = "comment";
    PermissionLevel["Write"] = "write";
    PermissionLevel["Admin"] = "admin";
})(PermissionLevel || (PermissionLevel = {}));
export const communityMemberId = (id) => id;
export const communityId = (id) => id;
export const groupId = (id) => id;
export const channelId = (id) => id;
export const channelPostId = (id) => id;
export const channelPostCommentId = (id) => id;
