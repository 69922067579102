import { createAsyncThunk } from '@reduxjs/toolkit';
import { COLLECTION } from '../../../../firestore/collectionNames';
import { COMMUNITY_GROUPS_REDUCER_NAME } from '../types';
import { groupId, } from '../../../../models/documents/Community';
const insertGroupIntoContentTree = (contentTree, insertIndex, group) => {
    const index = insertIndex ?? contentTree.length;
    return [...contentTree.slice(0, index), group, ...contentTree.slice(index)];
};
export const createCommunityGroup = createAsyncThunk(`${COMMUNITY_GROUPS_REDUCER_NAME}/createCommunityGroup`, async ({ onSuccess, onFailure, payload }, { rejectWithValue, extra: { db, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user) {
            throw new Error('User is not logged in');
        }
        const communityRef = db.collection(COLLECTION.COMMUNITIES).doc(payload.communityId);
        const community = await communityRef.get();
        const groupRef = communityRef.collection(COLLECTION.COMMUNITY_GROUPS).doc();
        await groupRef.set({
            ...payload,
            createdAt: new Date(),
            updatedAt: new Date(),
            createdBy: user.uid,
            id: groupId(groupRef.id),
        });
        const contentTree = community.data()?.contentTree || [];
        const updatedTree = insertGroupIntoContentTree(contentTree, payload.index, {
            id: groupId(groupRef.id),
            type: 'group',
            children: [],
        });
        await communityRef.update({
            contentTree: updatedTree,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        console.error({ payload, error: e });
        return rejectWithValue(e);
    }
});
