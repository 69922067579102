import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMMUNITIES_REDUCER_NAME } from '../types';
import { COLLECTION } from '../../../../firestore/collectionNames';
export const updateCommunity = createAsyncThunk(`${COMMUNITIES_REDUCER_NAME}/updateCommunity`, async ({ payload, onSuccess, onFailure }, { rejectWithValue, extra: { db, analytics, auth } }) => {
    try {
        const user = auth().currentUser;
        if (!user || !user.email) {
            throw new Error('user-is-not-logged');
        }
        const { id, ...updateData } = payload;
        const communityRef = db.collection(COLLECTION.COMMUNITIES).doc(id);
        await communityRef.update({
            ...updateData,
            updatedAt: new Date(),
        });
        analytics.track('update_community', {
            userId: user.uid,
            email: user.email,
            communityId: id,
        });
        onSuccess?.();
    }
    catch (e) {
        onFailure?.();
        return rejectWithValue(e);
    }
});
